<template>
  <div class="content_block">
    <div class="top">
      <b>公司实际经营管理人</b>
      <template v-if="!isDisabled">
        <div class="btnContainer" v-if="isEditing">
          <el-button type="default" size="mini"  @click="handleCancel" v-if="!isShowHead">取消</el-button>
          <el-button type="primary" size="mini" @click="handleSave" v-if="!isShowHead">保存</el-button>
          <el-button type="primary" size="mini" @click="handleUpdate" v-else>保存</el-button>
        </div>
        <div class="btnContainer" v-else>
          <el-button type="primary" plain icon="el-icon-edit" size="mini" @click="handleEdit">编辑</el-button>
        </div>
      </template>
    </div>
    <template v-if="isEditing">
      <el-form :model="form" ref="JZForm" :rules="rules" label-width="100px" label-position="right">
        <el-row>
          <el-col :span="12">
            <el-form-item label="名称" prop="contactUserName">
                <el-input placeholder="请输入名称" v-model.trim="form.contactUserName"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </template>
    <el-descriptions :colon="false" :column="2" style="margin-top: 10px;" v-else>
      <el-descriptions-item label="姓名" labelClassName="warningLabel" contentClassName="warningContent">{{ form.contactUserName }}</el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    concatLists: {
      type: Array,
      default () {
        return [];
      },
    },
    type: {
      type: String,
      default: '3',
    },
    isDisabled: {
      type: Boolean,
      default () {
        return false;
      },
    },
    isShowHead: {
      type: Boolean,
      default () {
        return false;
      },
    },
    activeName: {
      type: String,
      default: '',
    },
  },
  watch: {
    activeName: {
      handler (val) {
        if (val === 'ActualContacts') {
          let info = this.concatLists.find(i=>i.contactType === this.type);
          info = info ? JSON.parse(JSON.stringify(info)) : null;
          this.form = info ? {
            ...info,
            operateType: '2',
            contactType: this.type,
          } : {
            contactUserName: '',
            operateType: '1',
            contactType: this.type,
          };
          this.clearValidate();
        }
      },
      immediate: true,
      deep: true,
    },
    isShowHead: {
      handler (val) {
        if (val) {
          this.isEditing = this.isShowHead;
        }
      },
      immediate: true,
      deep: true,
    },
    concatLists: {
      handler (val) {
        if (val && val.length) {
          let info = val.find(i=>i.contactType === this.type);
          info = info ? JSON.parse(JSON.stringify(info)) : null;
          this.form = info ? {
            ...info,
            operateType: '2',
            contactType: this.type,
          } : {
            contactUserName: '',
            operateType: '1',
            contactType: this.type,
          };
          this.clearValidate();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    clearValidate () {
      this.$nextTick(()=>{
        this.$refs.JZForm && this.$refs.JZForm.clearValidate();
      });
    },
    handleEdit () {
      this.isEditing = true;
    },
    handleCancel () {
      let info = this.concatLists.find(i=>i.contactType === this.type);
      info = info ? JSON.parse(JSON.stringify(info)) : null;
      this.form = info ? {
        ...info,
        operateType: '2',
        contactType: this.type,
      } : {
        contactUserName: '',
        operateType: '1',
        contactType: this.type,
      };
      this.isEditing = false;
    },
    handleSave () {
      this.$refs.JZForm.validate(valid =>{
        if (valid) {
          let tenantFileReqDTOs = (this.form.tenantFileReqDTOs || []).map(i=>({
            ...i,
            contactType: this.type,
          }));
          this.$emit('updateContact', {...this.form, tenantFileReqDTOs}, this.type);
          this.isEditing = false;
        }
      });
    },
    handleUpdate () {
      this.$refs.JZForm.validate(valid =>{
        if (valid) {
          let tenantFileReqDTOs = (this.form.tenantFileReqDTOs || []).map(i=>({
            ...i,
            contactType: this.type,
          }));
          this.$emit('updateContact', {...this.form, tenantFileReqDTOs}, this.type);
        }
      });
    },
  },
  data () {
    return {
      isEditing: false,
      form: {
        contactUserName: '',
        operateType: '1',
        contactType: this.type,
      },
      rules: {
        contactUserName: [
          {
            required: true,
            message: '请输入姓名',
            trigger: ['blur', 'change'],
          },
        ],
      },
    };
  },
};
</script>
<style scoped lang='scss'>
/* @import url(); 引入css类 */
.content_block{
  padding: 0 20px 20px 20px;
  min-height: calc(100vh - 420px);
  .top{
    height: 40px;
    line-height: 40px;
    display: flex;
    .btnContainer{
      flex: 1;
      text-align:right;
    }
  }
}
/deep/ .warningLabel{
  width: 100px;
  text-align: right;
  display: inline-block;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #929AA6;
  letter-spacing: 0;
  text-align: right;
  line-height: 20px;
}
/deep/ .warningContent{
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #1F2733;
  letter-spacing: 0;
  text-align: left;
  line-height: 20px;
  display: flex;
}
</style>
